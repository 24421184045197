/*
Replace
nft with your resource Name :) Take care about different writings!
/!\ Nft nft Nfts nfts /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const votesSlice = createSlice({
  name: "votes",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    updateErrorReset: (state, action) => {
      state.updating = false;
      state.updated = false;
      state.updateError = null;
    },
    listErrorReset: (state, action) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
    createErrorReset: (state, action) => {
      state.creating = false;
      state.created = false;
      state.createError = null;
    },
    showErrorReset: (state, action) => {
      state.showing = false;
      state.showed = false;
      state.showError = null;
    },
  },
});

export const {
  show: showVote,
  update: updateVote,
  list: listVotes,
  create: createVote,
  updateErrorReset: updateVoteErrorReset,
  listErrorReset: listVoteErrorReset,
  createErrorReset: createVoteErrorReset,
  showErrorReset: showVoteErrorReset,
} = votesSlice.actions;
export const actions = votesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const votes = (state) =>
  state.votes.idsList.map((id) => state.votes.dictionary[id]);
export const vote = (state) =>
  state.votes.showId && state.votes.dictionary[state.votes.showId];
export const voteAttributes = (state) => vote(state)?.attributes;
export const voteRelationships = (state) => vote(state)?.relationships;
export const voteId = (state) => state.votes.showId;

export const getVote = createSelector(
  (state) => state.votes.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getVoteAttributes = createSelector(
  (state) => state.votes.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getVoteRelationships = createSelector(
  (state) => state.votes.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getVotes = createSelector(
  (state) => state.votes.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default votesSlice.reducer;
