/*
Replace
resourceName with your resource Name :) Take care about different writings!
/!\ ResourceName resourceName ResourceNames resourceNames /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const holdersSlice = createSlice({
  name: "holders",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    updateReset: (state, action) => {
      state.updating = false;
      state.updated = false;
      state.updateError = null;
    },
    listReset: (state, action) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
    createReset: (state, action) => {
      state.creating = false;
      state.created = false;
      state.createError = null;
    },
  },
});
export const {
  show: showHolder,
  list: listHolders,
  create: createHolder,
  createReset: createHolderReset,
  listReset: listHolderReset,
  updateReset: updateHolderReset,
} = holdersSlice.actions;

export const actions = holdersSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const holders = (state) =>
  state.holders.idsList.map((id) => state.holders.dictionary[id]);
export const holder = (state) =>
  state.holders.showId && state.holders.dictionary[state.holders.showId];
export const holderAttributes = (state) => holder(state)?.attributes;
export const holderRelationships = (state) => holder(state)?.relationships;
export const holderId = (state) => state.holders.showId;

export const getHolder = createSelector(
  (state) => state.holders.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getHolderAttributes = createSelector(
  (state) => state.holders.dictionary,

  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getHolderRelationships = createSelector(
  (state) => state.holders.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getHolders = createSelector(
  (state) => state.holders.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default holdersSlice.reducer;
