/*
Replace
nft with your resource Name :) Take care about different writings!
/!\ Nft nft Nfts nfts /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const votingOptionsSlice = createSlice({
  name: "votingOptions",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    updateErrorReset: (state, action) => {
      state.updating = false;
      state.updated = false;
      state.updateError = null;
    },
  },
});

export const {
  show: showVotingOption,
  update: updateVotingOption,
  list: listVotingOptions,
  updateErrorReset,
} = votingOptionsSlice.actions;
export const actions = votingOptionsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const votingOptions = (state) =>
  state.votingOptions.idsList.map((id) => state.votingOptions.dictionary[id]);
export const votingOption = (state) =>
  state.votingOptions.showId &&
  state.votingOptions.dictionary[state.votingOptions.showId];
export const votingOptionAttributes = (state) =>
  votingOption(state)?.attributes;
export const votingOptionRelationships = (state) =>
  votingOption(state)?.relationships;
export const votingOptionId = (state) => state.nfts.showId;

export const getVotingOption = createSelector(
  (state) => state.votingOptions.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getVotingOptionAttributes = createSelector(
  (state) => state.votingOptions.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getVotingOptionRelationships = createSelector(
  (state) => state.votingOptions.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getVotingOptionNftIds = createSelector(
  (state) => state,
  (_, id) => id,
  (fullState, id) => {
    return fullState.votingOptions?.dictionary[
      id
    ]?.relationships?.votes?.data?.map((voteData) => {
      return fullState.votes?.dictionary[voteData.id]?.relationships?.nft?.data
        ?.id;
    });
  }
);

export const getVotingOptions = createSelector(
  (state) => state.votingOptions.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default votingOptionsSlice.reducer;
