/**
 * Actions and ActionTypes definitions for showing/hiding an flash alert.
 * ActionTypes are the constants used to specify a specific action.
 * Actions are generated with redux-actions' createAction method.
 */
import { createAction } from "redux-actions";

/** Show action-types */
const SHOW_ALERT = "notifications/SHOW_ALERT";
/** Hide action-types */
const HIDE_ALERT = "notifications/HIDE_ALERT";

/**
 * showAlert action
 */
const showAlert = createAction(SHOW_ALERT);

/**
 * hideAlert action
 */
const hideAlert = createAction(HIDE_ALERT);

/** exports all actions as default */
const actions = {
  showAlert: showAlert,
  hideAlert: hideAlert,
};

export default actions;
