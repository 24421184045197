/**
 *  Reducer function for the user account management
 *
 * It exports a defaultState used as initial state for this reducer.
 * Each action is handled with the handleActions method from redux-actions and is bound to the actions defined in actions/account
 *
 */
import { handleActions } from "redux-actions";
import actions from "./actions";

/** The account reducer initial state */
export const defaultState = {
  stored: true,
  currentUser: null,
  signingin: false,
  signedin: false,
  loginError: null,
  logoutError: null,
  registering: false,
  registered: false,
  registerError: null,
  recovering: false,
  recovered: false,
  pwdRecoveryError: null,
  resetting: false,
  resetted: false,
  pwdResetError: null,
  successMessage: null,
};

/** List of actions handled by reducer */
export default handleActions(
  {
    /** The login action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.login]: (state) => {
      return {
        ...state,
        signingin: true,
        loginError: null,
      };
    },
    /** The login action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the current user data with authentication tokens
     */
    [actions.loginSuccess]: (state, action) => {
      return {
        ...state,
        signingin: false,
        signedin: true,
        currentUser: action.payload.data,
        loginError: null,
        successMessage: null,
      };
    },
    /** The login action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.error the login error
     */
    [actions.loginFail]: (state, action) => {
      return {
        ...state,
        signingin: false,
        signedin: false,
        loginError: action.error,
        successMessage: null,
      };
    },
    /** The logout action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.logout]: (state) => {
      return {
        ...state,
        logoutError: null,
      };
    },
    /** The logout action success
     * @param {Object} state the current state of this reducer
     */
    [actions.logoutSuccess]: (state) => {
      return {
        ...state,
        signingin: false,
        signedin: false,
        currentUser: null,
        logoutError: null,
      };
    },
    /** The logout action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.error the logout error
     */
    [actions.logoutFail]: (state, action) => {
      return {
        ...state,
        logoutError: action.error,
        currentUser: null,
        signedin: false,
      };
    },
    /** The register action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.register]: (state) => {
      return {
        ...state,
        registering: true,
      };
    },
    /** The register action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the current user data without authentication tokens (needs confirmation first)
     */
    [actions.registerSuccess]: (state, action) => {
      return {
        ...state,
        registering: false,
        registered: true,
        currentUser: action.payload.data,
        registerError: null,
        successMessage: `We have sent a confirmation mail at ${action.payload.data.email}. Click on the link to activate your account.`,
      };
    },
    /** The register action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.error the register error
     */
    [actions.registerFail]: (state, action) => {
      return {
        ...state,
        registering: false,
        registered: false,
        registerError: action.error,
      };
    },
    /** The password recovery action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.pwdRecovery]: (state) => {
      return {
        ...state,
        pwdRecoveryError: null,
        recovering: true,
      };
    },
    /** The recovery action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the success message
     */
    [actions.pwdRecoverySuccess]: (state, action) => {
      return {
        ...state,
        recovering: false,
        recovered: true,
        pwdRecoveryError: null,
        successMessage: action.payload.message,
      };
    },
    /** The recovery action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.error the recovery error
     */
    [actions.pwdRecoveryFail]: (state, action) => {
      return {
        ...state,
        recovering: false,
        recovered: false,
        pwdRecoveryError: action.error,
      };
    },
    /** The password reset action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.pwdReset]: (state) => {
      return {
        ...state,
        pwdResetError: null,
        resetting: true,
      };
    },
    /** The reset action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the current user
     */
    [actions.pwdResetSuccess]: (state, action) => {
      return {
        ...state,
        resetting: false,
        resetted: true,
        pwdResetError: null,
        currentUser: action.payload.data,
        signedin: true,
        successMessage: null,
      };
    },
    /** The reset action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.error the reset error
     */
    [actions.pwdResetFail]: (state, action) => {
      return {
        ...state,
        resetting: false,
        resetted: false,
        pwdResetError: action.error,
      };
    },
    /** The user update action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.updateProfile]: (state) => {
      return {
        ...state,
        updateError: null,
        updating: true,
      };
    },
    /** The user update success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the current user
     */
    [actions.updateProfileSuccess]: (state, action) => {
      return {
        ...state,
        updating: false,
        updated: true,
        updateError: null,
        currentUser: { ...action.payload.data },
      };
    },
    /** The user update fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.error the reset error
     */
    [actions.updateProfileFail]: (state, action) => {
      return {
        ...state,
        updating: false,
        updated: false,
        updateError: action.error,
      };
    },
    [actions.clearErrors]: (state, action) => {
      return {
        ...state,
        loginError: null,
        logoutError: null,
        registerError: null,
        pwdRecoveryError: null,
        pwdResetError: null,
        successMessage: null,
      };
    },
  },
  defaultState
);
