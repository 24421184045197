/*
Replace
nft with your resource Name :) Take care about different writings!
/!\ Nft nft Nfts nfts /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const eventRegistrationsSlice = createSlice({
  name: "eventRegistrations",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    updateErrorReset: (state, action) => {
      state.updating = false;
      state.updated = false;
      state.updateError = null;
    },
    listErrorReset: (state, action) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
    createErrorReset: (state, action) => {
      state.creating = false;
      state.created = false;
      state.createError = null;
    },
    showErrorReset: (state, action) => {
      state.showing = false;
      state.showed = false;
      state.showError = null;
    },
    createRegistrationErrorReset: (state, action) => {
      state.creatingRegistration = false;
      state.createdRegistration = false;
      state.createRegistrationError = null;
    },
  },
});

export const {
  show: showEventRegistration,
  update: updateEventRegistration,
  list: listEventRegistrations,
  create: createEventRegistration,
  updateErrorReset: updateEventRegistrationErrorReset,
  listErrorReset: listEventRegistrationErrorReset,
  createErrorReset: createEventRegistrationErrorReset,
  showErrorReset: showEventRegistrationErrorReset,
} = eventRegistrationsSlice.actions;
export const actions = eventRegistrationsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const eventRegistrations = (state) =>
  state.eventRegistrations.idsList.map(
    (id) => state.eventRegistrations.dictionary[id]
  );
export const eventRegistration = (state) =>
  state.eventRegistrations.showId &&
  state.eventRegistrations.dictionary[state.eventRegistrations.showId];
export const eventRegistrationAttributes = (state) =>
  eventRegistration(state)?.attributes;
export const eventRegistrationRelationships = (state) =>
  eventRegistration(state)?.relationships;
export const eventRegistrationId = (state) => state.eventRegistrations.showId;

export const getEventRegistration = createSelector(
  (state) => state.eventRegistrations.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getEventRegistrationAttributes = createSelector(
  (state) => state.eventRegistrations.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getEventRegistrationRelationships = createSelector(
  (state) => state.eventRegistrations.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getEventRegistrations = createSelector(
  (state) => state.eventRegistrations.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default eventRegistrationsSlice.reducer;
