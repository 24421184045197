/**
 *  Reducer function for the serviceWorker management
 *
 * It exports a defaultState used as initial state for this reducer.
 * Each action is handled with the handleActions method from redux-actions and is bound to the actions defined in actions/servWork
 *
 */
import { handleActions } from "redux-actions";
import actions from "./actions";

/** The service worker reducer initial state */
export const defaultState = {
  stored: true,
  savedPushSubscription: false,
  lastSubscriptionSavedAt: null,
  savingPushSubscription: false,
  pushSubscription: null,
};

export default handleActions(
  {
    /** The store push subscription action. Saves the push subscription data in the store
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the push subscription data
     */
    [actions.storePushSubscription]: (state, action) => {
      return {
        ...state,
        pushSubscription: action.payload,
      };
    },
    /** The send push subscription action trigger
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the push subscription data
     */
    [actions.sendPushSubscription]: (state, action) => {
      return {
        ...state,
        pushSubscription: action.payload,
        savingPushSubscription: true,
      };
    },
    /** The send push subscription action success
     * @param {Object} state the current state of this reducer
     */
    [actions.sendPushSubscriptionSuccess]: (state) => {
      return {
        ...state,
        savedPushSubscription: true,
        savingPushSubscription: false,
        lastSubscriptionSavedAt: new Date(),
      };
    },
    /** The send push subscription action fail. Fails silently
     * @param {Object} state the current state of this reducer
     */
    [actions.sendPushSubscriptionFail]: (state) => {
      return {
        ...state,
        savingPushSubscription: false,
        savedPushSubscription: false,
      };
    },
  },
  defaultState
);
