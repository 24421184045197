/*
Replace
nft with your resource Name :) Take care about different writings!
/!\ Nft nft Nfts nfts /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const unlockInputsSlice = createSlice({
  name: "unlockInputs",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    updateErrorReset: (state, action) => {
      state.updating = false;
      state.updated = false;
      state.updateError = null;
    },
    listErrorReset: (state, action) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
    createErrorReset: (state, action) => {
      state.creating = false;
      state.created = false;
      state.createError = null;
    },
    showErrorReset: (state, action) => {
      state.showing = false;
      state.showed = false;
      state.showError = null;
    },
  },
});

export const {
  show: showUnlockInput,
  update: updateUnlockInputs,
  list: listUnlockInputs,
  create: createUnlockInputs,
  updateErrorReset: updateUnlockInputsErrorReset,
  listErrorReset: listUnlockInputsErrorReset,
  createErrorReset: createUnlockInputsErrorReset,
  showErrorReset: showUnlockInputsErrorReset,
} = unlockInputsSlice.actions;
export const actions = unlockInputsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const unlockInputs = (state) =>
  state.unlockInputs.idsList.map((id) => state.unlockInputs.dictionary[id]);
export const unlockInput = (state) =>
  state.unlockInputs.showId &&
  state.unlockInputs.dictionary[state.unlockInputs.showId];
export const unlockInputAttributes = (state) => unlockInput(state)?.attributes;
export const unlockInputRelationships = (state) =>
  unlockInput(state)?.relationships;
export const unlockInputId = (state) => state.unlockInputs.showId;

export const listUnlockInputsForUnlockable = createSelector(
  (state) => state.unlockInputs.dictionary,
  (_, unlockable) => unlockable,
  (dictionary, unlockable) => {
    return Object.keys(dictionary).map((unlockInputDictionaryId) => {
      return dictionary[unlockInputDictionaryId]?.relationships?.unlockable
        ?.data?.id === unlockable.id &&
        dictionary[unlockInputDictionaryId]?.relationships?.unlockable?.data
          ?.type === unlockable.type
        ? dictionary[unlockInputDictionaryId]
        : false;
    });
  }
);

export const getUnlockInput = createSelector(
  (state) => state.unlockInputs.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getUnlockInputAttributes = createSelector(
  (state) => state.unlockInputs.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getUnlockInputRelationships = createSelector(
  (state) => state.unlockInputs.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getUnlockInputs = createSelector(
  (state) => state.unlockInputs.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export const getUnlockInputsAttributes = createSelector(
  (state) => state.unlockInputs.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return (
      ids &&
      dictionary &&
      ids.map((id) => ({ ...dictionary[id]?.attributes, id: id }))
    );
  }
);

export default unlockInputsSlice.reducer;
